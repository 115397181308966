//environment
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthenticationService {
    constructor(http) {
        this.http = http;
        this.tokenData = null;
        let that = this;
        let tokenInterval = setInterval(function () {
            if (that.tokenData && that.tokenData.expires_in) {
                that.tokenData.expires_in -= 1;
                if (that.tokenData.expires_in == 0)
                    clearInterval(tokenInterval);
            }
        }, 60000);
    }
    setAuthToken(data) {
        this.tokenData = data;
    }
    getAuthToken() {
        return this.tokenData;
    }
    validateAuth(data) {
        let payload = { authenticationkey: data.authenticationkey, timestamp: data.timestamp };
        let url = environment.API.Validate.url + `/uniqueid/${data.uniqueid}/agentextension/${data.agentextension}/validate`;
        return this.http.post(url, payload);
    }
    ;
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
