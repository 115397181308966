import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class AppDataService {
    constructor() {
        this.appData = {
            uniqueID: '',
            agentExtension: '',
            authKey: ''
        };
        this.loader$ = new Subject();
    }
    setAppData(data) {
        this.appData = data;
    }
    getAppData() {
        return this.appData;
    }
}
AppDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppDataService_Factory() { return new AppDataService(); }, token: AppDataService, providedIn: "root" });
