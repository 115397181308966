import { Routes } from '@angular/router';
const ɵ0 = () => import("./_modules/mainscreen/mainscreen.module.ngfactory").then((m) => m.MainscreenModuleNgFactory), ɵ1 = () => import("./_modules/error/error.module.ngfactory").then((m) => m.ErrorModuleNgFactory), ɵ2 = () => import("./_modules/not-found/not-found.module.ngfactory").then((m) => m.NotFoundModuleNgFactory);
const routes = [
    {
        path: "",
        loadChildren: ɵ0,
    },
    {
        path: 'error',
        loadChildren: ɵ1,
    },
    {
        path: '**',
        loadChildren: ɵ2,
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
